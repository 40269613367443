import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

export const featureImage = graphql`
  fragment featureImage on File {
    childImageSharp {
      fixed(width: 200, height: 150) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`
const Features = () => {
  const images = useStaticQuery(graphql`
    query {
      rocket: file(relativePath: { eq: "features/speed.png" }) {
        ...featureImage
      }

      ssd: file(relativePath: { eq: "features/downloads.png" }) {
        ...featureImage
      }

      secure: file(relativePath: { eq: "features/security.png" }) {
        ...featureImage
      }
    }
  `)
  return (
    <section className="features">
      <div className="row">
        <div className="col-sm-12">
          <h2 className="why-cboxera">Why Choose CboxEra.com</h2>
          <span>
            Cboxera is the best tool which helps users for downloading files
            online with ease by providing safe and anonymous downloads. CBOXERA
            is one stop solution for all 50+ filehosts.
          </span>
        </div>
      </div>
      <div className="row spacing-70">
        <div className="col-sm-4">
          <div className="feature">
            <Img
              fixed={images.rocket.childImageSharp.fixed}
              alt="peak speed meter"
            />
            <h3 className="mt-0">Rocket Speed Transfers</h3>
            <p>
              Cboxera guarantees you that maximum speed under all circumstances,
              except that file hoster isn't having bandwidth issues itself or
              any means.
            </p>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="feature">
            <Img
              fixed={images.ssd.childImageSharp.fixed}
              alt="content download"
            />
            <h3 className="mt-0">Downloads</h3>
            <p>
              Download links which are generated at cboxera are proxied directly
              from file hosting sites, so we don't store any files on our
              servers at any cases.
            </p>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="feature">
            <Img
              fixed={images.secure.childImageSharp.fixed}
              alt="high data security"
            />
            <h3 className="mt-0">Highest Data Security</h3>
            <p>
              Cboxera provides all links in HTTPS, your downloads being
              encrypted, any attempt to identify what you are downloading
              becomes impossible.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features
