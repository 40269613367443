import React from 'react'

const Stats = () => (
  <section className="statistics">
    <div className="row">
      <div className="col-sm-12">
        <h2>
          A few <i className="fa fa-heart text-danger" /> lovely stats
        </h2>
        <p>
          <i className="far fa-grin-hearts text-warning h2 pr-2" />
          Some more reasons to choose cboxera.com services.
        </p>
      </div>
    </div>

    <div className="row spacing-70">
      <div className="col-sm-3">
        <div>
          <h2 className="font-weight-bold">
            <i className="far fa-clock pr-2" />
            99.9%
          </h2>
          <span className="font-weight-bold">Servers Uptime</span>
        </div>
      </div>

      <div className="col-sm-3">
        <div className="wow fadeInUp" data-wow-delay="0.2s">
          <h2 className="font-weight-bold">
            <i className="fas fa-cloud-download-alt pr-2" />2 TB+
          </h2>
          <span className="font-weight-bold">Downloads served everyday</span>
        </div>
      </div>

      <div className="col-sm-3">
        <div className="wow fadeInUp" data-wow-delay="0.3s">
          <h2 className="font-weight-bold">
            <i className="fas fa-database pr-2" />
            50+
          </h2>
          <span className="font-weight-bold">Filehosts Supported</span>
        </div>
      </div>

      <div className="col-sm-3">
        <div className="wow fadeInUp" data-wow-delay="0.4s">
          <h2 className="font-weight-bold">
            <i className="fas fa-users pr-2" />
            45k+
          </h2>
          <span className="font-weight-bold">Satisfied Customers</span>
        </div>
      </div>
    </div>
  </section>
)
export default Stats
