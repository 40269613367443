import React, { useState, useEffect } from 'react'
import Cookie from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'

import MainSlider from './MainSlider'
import Features from './Features'
import Plans from './Plans'
import Stats from './Stats'
import Adsense from '../Adsense'
import HowToDownload from './HowToDownload'
import HostsAdded from '../HostStatus/HostsAdded'

import InvisibleRepatcha from '../InvisibleRepatcha'
import { userLogin } from '../../reducers/login'
import { isKeyExists } from '../../helpers'

import { HOSTS } from '../../data/hosts'

const Home = () => {
  const [login, setLogin] = useState({ email: '', password: '' })
  const { isLoggedIn } = useSelector(state => state.login)
  const dispatch = useDispatch()
  useEffect(() => {
    const gCookie = Cookie.get('Glogin')
    const gCookieVal = gCookie ? Number(gCookie) : 0
    if (
      gCookieVal === 0 &&
      !isLoggedIn &&
      isKeyExists(window.navigator, 'credentials.get')
    ) {
      navigator.credentials.get({ password: true }).then(
        user => {
          if (user) {
            const { id, password, type } = user
            if (type === 'password' && id && password) {
              setLogin({
                email: id,
                password,
              })
              const { grecaptcha } = window
              if (grecaptcha) grecaptcha.execute()
            }
          }
          Cookie.set('Glogin', 1, { expires: 1 })
        },
        error => console.log(error)
      )
    }
  }, [isLoggedIn])

  const handleGoogleLogin = token => {
    const data = {
      ...login,
      token,
    }
    dispatch(userLogin(data))
  }

  return (
    <>
      <MainSlider />
      <Features />
      <Adsense slot="6222161092" format="responsive" />
      <Plans />
      <Adsense slot="5602040533" format="fixed" />
      <HowToDownload headingAs="2" />
      <Adsense slot="6222161092" format="responsive" />
      <Stats />
      <InvisibleRepatcha onResolved={handleGoogleLogin} />
      <HostsAdded title="Supported Hosts!" hosts={HOSTS} shouldAnimate />
    </>
  )
}

export default Home
