import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const HostsAdded = ({ title, hosts, shouldAnimate = false }) => (
  <section className="domains">
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <h2>{title}</h2>
          <hr className="small" />
        </div>
      </div>
      <div className={`row ${shouldAnimate ? 'animated' : 'no-animation'}`}>
        {hosts.map(host => (
          <div className="col" key={host.name}>
            <div className="item">
              <div className="extension">
                <Link
                  to={`/host/${host.name.split('.')[0]}-generator`}
                  className="host-name-color"
                >
                  <i
                    title={host.name}
                    className={`hosts_${host.name.replace(/\./g, '_')} pr-4`}
                  ></i>
                  {host.name}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
)

HostsAdded.propTypes = {
  title: PropTypes.string.isRequired,
  hosts: PropTypes.array.isRequired,
  shouldAnimate: PropTypes.bool.isRequired,
}

export default HostsAdded
