export const HOSTS = [
  {
    name: '1fichier.com',
    isNew: false,
    isPopular: true,
  },
  {
    name: '4shared.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'alfafile.net',
    isNew: false,
    isPopular: true,
  },
  {
    name: 'anonfile.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'anonfiles.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'clicknupload.to',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'ddl.to',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'ddownload.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'depositfiles.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'drop.download',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'easybytez.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'elitefile.net',
    isNew: true,
    isPopular: true,
  },
  {
    name: 'extmatrix.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'file.al',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'filefactory.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'filefox.cc',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'filenext.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'filer.net',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'filestore.to',
    isNew: true,
    isPopular: false,
  },
  {
    name: 'filespace.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'fireget.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'flash-files.com',
    isNew: true,
    isPopular: true,
  },
  {
    name: 'gigapeta.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'hitfile.net',
    isNew: true,
    isPopular: false,
  },
  {
    name: 'icerbox.com',
    isNew: true,
    isPopular: true,
  },
  {
    name: 'indishare.org',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'isra.cloud',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'katfile.com',
    isNew: false,
    isPopular: true,
  },
  {
    name: 'mediafire.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'mega.nz',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'mexa.sh',
    isNew: true,
    isPopular: true,
  },
  {
    name: 'nippyshare.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'novafile.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'prefiles.com',
    isNew: false,
    isPopular: true,
  },
  {
    name: 'rapidgator.net',
    isNew: false,
    isPopular: true,
  },
  {
    name: 'sendspace.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'solidfiles.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'subyshare.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'syncs.online',
    isNew: true,
    isPopular: true,
  },
  {
    name: 'turbobit.net',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'tusfiles.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'uloz.to',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'unibytes.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'uploadboy.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'uploadcloud.pro',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'uploadgig.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'uppit.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'userscloud.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'wdupload.com',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'wipfiles.net',
    isNew: false,
    isPopular: false,
  },
  {
    name: 'wupfile.com',
    isNew: false,
    isPopular: true,
  },
  {
    name: 'wushare.com',
    isNew: false,
    isPopular: true,
  },
  {
    name: 'youtube.com',
    isNew: false,
    isPopular: false,
  },
]

export const NEW_HOSTS = HOSTS.filter(host => host.isNew)
