import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap'
import Img from 'gatsby-image'

const items = [
  {
    src: 'image1',
    altText: 'HIGH SPEED & SAFE DOWNLOADING',
    caption: 'Reliable and Secured Downloads',
    caption1: 'Be Anonymous Online !',
  },
  {
    src: 'image2',
    altText: 'BEST IN CLASS DEDICATED SERVERS',
    caption: 'SSD Dedicated Servers',
    caption1: 'Giving you High Speed for Faster And Reliable Downloading!',
  },
  {
    src: 'image3',
    altText: 'RESUMABLE DOWNLOADS ALL TIME',
    caption: 'All Hosts Support !',
    caption1: 'No DownTime & No Server Faults',
  },
  {
    src: 'image4',
    altText: 'Award Winning SupportTeam',
    caption: 'All Hosts Support',
    caption1: '99.9% Uptime Service 24/7/365 Server Support',
  },
]

export default () => {
  const images = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "slider/12.jpg" }) {
        ...sliderImage
      }

      image2: file(relativePath: { eq: "slider/1.jpg" }) {
        ...sliderImage
      }

      image3: file(relativePath: { eq: "slider/3.jpg" }) {
        ...sliderImage
      }

      image4: file(relativePath: { eq: "slider/4.jpg" }) {
        ...sliderImage
      }
    }
  `)
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map(item => {
    const { src } = item
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={src}
      >
        <Img fluid={images[src].childImageSharp.fluid} />
        <div className="carousel-caption d-none d-md-block">
          <h1>{item.altText}</h1>
          <p>{item.caption}</p>
          <p>{item.caption1}</p>
        </div>
      </CarouselItem>
    )
  })

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  )
}

export const sliderImage = graphql`
  fragment sliderImage on File {
    childImageSharp {
      fluid(maxWidth: 1800, maxHeight: 514) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
