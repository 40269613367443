import React from 'react'

import Index from '../components/Home'
import SEO from '../components/SEO'

const IndexPage = () => {
  return (
    <>
      <SEO page="home" />
      <Index />
    </>
  )
}

export default IndexPage
